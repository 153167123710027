<template>
    <div class="container">
        <el-row>
            <content-title :nav="nav"></content-title>
        </el-row>
        <el-descriptions title="服务信息" v-if="course.id">
            <template slot="extra">
                <el-button type="primary" size="small" @click="openEdit">修改信息</el-button>
            </template>
            <el-descriptions-item label="服务名称">
                {{ course.name }}
                <el-tag size="small" v-if="course.is_recommend">优选</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="服务类型">
                <p v-if="course.type==1">时间预约</p>
                <p v-if="course.type==2">次卡</p>
                <p v-if="course.type==3">天数</p>
                <p v-if="course.type==4">套餐</p>
            </el-descriptions-item>

            <el-descriptions-item label="服务类目">
                {{ course.categories }}
            </el-descriptions-item>

            <el-descriptions-item label="售价">
                ￥{{ course.price }}
            </el-descriptions-item>
            <el-descriptions-item label="高峰售价" v-if="course.type==1">
                ￥{{ course.big_price }}
            </el-descriptions-item>
            <el-descriptions-item label="结算价">
                ￥{{ course.settlement_price }}
            </el-descriptions-item>
            <el-descriptions-item label="高峰结算价" v-if="course.type==1">
                ￥{{ course.big_settlement_price }}
            </el-descriptions-item>

            <el-descriptions-item label="服务次数" v-if="course.type!=1">
                {{ course.num }}
            </el-descriptions-item>
            
            <el-descriptions-item label="服务时长">
                {{ course.duration }}小时/次
            </el-descriptions-item>

            <el-descriptions-item label="已售数量">
                {{ course.sold_out }}
            </el-descriptions-item>
            <el-descriptions-item label="状态">
                    <el-tag size="small" v-if="course.status == 0">待审核</el-tag>
                    <el-tag size="small" type="success" v-if="course.status == 1">上架</el-tag>
                    <el-tag size="small" type="info" v-if="course.status == 2">下架</el-tag>
            </el-descriptions-item>
        </el-descriptions>

        <el-descriptions title="服务详情" v-if="course.id">
            <el-descriptions-item label="所属机构">
                <link-to :to="{ name: 'mecDetails', query: { mecID: course.mechanism_id } }">
                    {{ course.mechanismName }}
                </link-to>
            </el-descriptions-item>
            <el-descriptions-item label="所属场馆">
                <link-to :to="{ name: 'venueDetails', params: { id: course.venue_id } }">
                    {{ course.venueName }}
                </link-to>
            </el-descriptions-item>
            <el-descriptions-item label="服务详情">
                {{ course.introduction }}
            </el-descriptions-item>

            <el-descriptions-item label="服务封面">
                <el-image style="width: 150px; height: 150px" :src="course.image" :fit="cover"
                    :preview-src-list="[course.image]"></el-image>
            </el-descriptions-item>

            <el-descriptions-item label="服务介绍">
                <el-image v-for="(pic, index) in course.introduction_img.split(',')" style="width: 150px; height: 150px"
                    :src="pic" :fit="cover" :preview-src-list="course.introduction_img.split(',')"
                    :key="index"></el-image>
            </el-descriptions-item>
        </el-descriptions>
        <addService mode="edit" :Data="course" ref="course" @success="getCourseInfo"></addService>

    </div>
</template>

<script>
import addService from "@/views/courseManage/components/service/addService.vue";

export default {
    components: {
        addService,
    },

    data() {
        return {
            nav: { firstNav: "服务管理", secondNav: "服务详情" },
            id: "",
            course: {},
        };
    },

    mounted() {
        this.id = this.$route.params.id
        this.getCourseInfo()
    },

    methods: {
        openEdit() {
            this.$refs.course.open()
        },
        getCourseInfo() {
            this.$axios({
                url: "/user/venue/server/queryManagerListPage",
                params: {
                    id: this.id
                }
            }).then(res => {
                this.course = res.data.data.rows[0]
            })
        }
    },
};
</script>

<style lang="less" scoped></style>