export const rules ={
    type: [
        { required: true, message: "", trigger: "change" },
      ],
    name: [
      { required: true, message: "标题不能为空!", trigger: "change" }
    ],
    mechanism_id: [
      { required: true, message: "绑定机构!", trigger: "change" },
    ],
    venue_id: [
      { required: true, message: "绑定场馆!", trigger: "change" },
    ],
    categories: [
      { required: true, message: "请选择类目!", trigger: "change" },
    ],
    discount_price: [
      { required: true, message: "请填入价格!", trigger: "change" },
    ],
    settlement_price: [
      { required: true, message: "请填入结算价!", trigger: "change" },
    ],

  }