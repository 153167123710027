<template>
  <div>
    <el-dialog :visible.sync="addServiceDialog" width="80%" title="服务">
      <el-form
        :model="form"
        label-width="auto"
        :rules="rules"
        ref="courseForm"
        style="display: flex; justify-content: space-around; flex-wrap: wrap"
      >
        <div>
          <el-form-item label="服务名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="绑定机构">
            <el-select
              v-model="form.mechanism_id"
              :placeholder="
                form.map
                  ? form.map.mechanismInfo.mechanism_name || ''
                  : '输入机构名称或手机号'
              "
              filterable
              clearable
              remote
              :remote-method="searchOrgName"
            >
              <el-option
                v-for="(item, index) in mechanism_name_list"
                :key="index"
                :label="item.mechanism_name"
                :value="item.id"
                @click.native="getAddress(item)"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属场馆" prop="venue_id" required>
            <el-select
              v-model="form.venue_id"
              remote
              :remote-method="getVenuesList"
              filterable
              clearable
              @focus="getVenuesList"
            >
              <el-option
                v-for="(item, index) in venues_list"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务类型" prop="type">
            <el-radio v-model="form.type" :label="1">预约</el-radio>
            <el-radio v-model="form.type" :label="2">次卡</el-radio>
            <el-radio v-model="form.type" :label="3">天数</el-radio>
            <el-radio v-model="form.type" :label="4">套餐</el-radio>
          </el-form-item>
          <el-form-item label="次数" v-if="form.type == '2'" key="c2">
            <el-input
              v-model="form.num"
              placeholder="请输入服务的可使用次数"
            ></el-input
            >次
          </el-form-item>
          <el-form-item label="天数" v-if="form.type == '3'" key="c3">
            <el-input
              v-model="form.num"
              placeholder="请输入服务的可使用天数"
            ></el-input>
          </el-form-item>
          <el-form-item label="次数" v-if="form.type == '4'" key="c4">
            <el-input
              v-model="form.num"
              placeholder="请输入服务的可使用次数"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务时长" prop="duration" required>
            <el-input
              v-model.number="form.duration"
              placeholder="每次服务的时长,1小时、2小时等"
            ></el-input
            ><span>小时</span>
          </el-form-item>
          <el-form-item label="是否推荐">
            <el-checkbox
              v-model="form.is_recommend"
              :true-label="1"
              :false-label="0"
            ></el-checkbox>
          </el-form-item>
          <div v-if="form.type != '1'">
            <el-form-item label="门店单次金额">
              <el-input
                @change="priceChange"
                key="p3"
                v-model="venue_price"
                placeholder="门店单次金额（每次、每天）"
              ></el-input
              >元
            </el-form-item>
            <el-form-item label="结算金额" prop="settlement_price">
              <el-input
                key="p3"
                @change="priceChange"
                v-model="form.settlement_price"
                placeholder="结算金额（每次核销）"
              ></el-input
              >元
            </el-form-item>
            <div class="calc" v-show="calcShow">{{ calcText }}</div>
            <el-form-item label="售价(总)" prop="discount_price">
              <el-input
                key="p2"
                v-model="form.discount_price"
                placeholder="售价（总价）"
              ></el-input
              >元
            </el-form-item>
            <el-form-item label="原价(总)">
              <el-input
                key="p1"
                v-model="form.price"
                placeholder="原价（总价）"
              ></el-input
              >元
            </el-form-item>
          </div>
          <div v-if="form.type == '1'">
            <el-form-item label="门店闲时价格">
              <el-input
                v-model="primary_price"
                placeholder="门店闲时价格"
                @change="priceChangePrimary"
              ></el-input
              >元/小时
            </el-form-item>
            <el-form-item label="闲时结算价" prop="settlement_price">
              <el-input
                @change="priceChangePrimary"
                v-model="form.settlement_price"
                placeholder="闲时结算价"
              ></el-input
              >元/小时
            </el-form-item>
            <el-form-item label="闲时价格" prop="discount_price">
              <el-input
                v-model="form.discount_price"
                placeholder="闲时价格"
              ></el-input
              >元/小时
            </el-form-item>
            <el-form-item label="门店高峰价格">
              <el-input
                v-model="big_price"
                placeholder="门店高峰价格"
                @change="priceChangeBig"
              ></el-input
              >元/小时
            </el-form-item>
            <el-form-item label="高峰结算价">
              <el-input
                v-model="form.big_settlement_price"
                placeholder="高峰结算价"
                @change="priceChangeBig"
              ></el-input
              >元/小时
            </el-form-item>
            <el-form-item label="高峰价格">
              <el-input
                v-model="form.big_price"
                placeholder="高峰价格"
              ></el-input
              >元/小时
            </el-form-item>
          </div>
        </div>
        <div>
          <el-form-item label="类目" prop="categories" required>
            <el-select v-model="form.categories" placeholder="类目">
              <el-option
                v-for="(item, index) in categories_child_list"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="封面" ref="imgUpRef" required>
            <avatar-uploader
              @getImgUrl="getCover"
              :url="form.image"
              :clear="if_clear"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label="服务说明">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="如：服务介绍、使用说明等"
              v-model="form.introduction"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="介绍图片">
            <images-uploader
              @getImgUrl="getProductImg"
              :urls="form.introduction_img"
              :clear="if_clear"
            >
            </images-uploader>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addServiceDialog = false">取 消</el-button>
        <el-button
          v-if="mode == 'add'"
          type="primary"
          @click="submitCourseForm()"
          >添 加</el-button
        >
        <el-button v-if="mode == 'edit'" type="primary" @click="updateForm()"
          >修 改</el-button
        >
        <el-button v-if="mode == 'check'" type="primary" @click="updateForm()"
          >修改并上架</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { rules } from "./rules";
import { ObjectChangedValue } from "@/js/utils";
export default {
  data() {
    return {
      form: {},
      temp: {},
      rules: rules,
      addServiceDialog: false,
      select_mechanism: "",
      mechanism_name_list: [],
      venues_list: "",
      if_clear: true,
      count: 1,
      calcShow: false,
      calcText: "正在计算价格...",
      venue_price: "", //门店价格
      primary_price: "", //门店闲时价格
      big_price: "", //门店高峰价格
    };
  },
  props: {
    Data: {
      type: Object,
    },
    mode: {
      type: String,
      default: "add",
    },
  },
  computed: {
    categories_child_list() {
      return this.$store.state.categories_child_list;
    },
  },
  watch: {

   addServiceDialog(){
this.venue_price= ""
      this.primary_price= ""
      this.big_price= ""
   },
    Data: {
      immediate: true,
      handler: function (val, oldVal) {
        this.editService();
      },
       deep: true,
    },
    mode: {
      immediate: true,
      handler: function (val, oldVal) {
        if (val == "add") {
          this.form = {};
        }
      },
    },
  },
  mounted() {},

  methods: {
    priceChangeBig() {
      if (this.big_price > 0 && this.form.big_settlement_price > 0) {
        this.getPoint(this.big_price, this.form.settlement_price).then(
          (res) => {
            let price = (res.data.data.point * 10).toFixed(2);
            this.$set(this.form, "big_price", price);
          }
        );
      }
    },
    priceChangePrimary() {
      if (this.primary_price > 0 && this.form.settlement_price > 0) {
        this.getPoint(this.primary_price, this.form.settlement_price).then(
          (res) => {
            let price = (res.data.data.point * 10).toFixed(2);
            this.$set(this.form, "discount_price", price);
          }
        );
      }
    },
    priceChange() {
      if (this.venue_price > 0 && this.form.settlement_price > 0) {
        this.calcShow = true;
        this.calcText = "正在计算价格...";
        this.getPoint(this.venue_price, this.form.settlement_price).then(
          (res) => {
            let price = (res.data.data.point * 10).toFixed(2);
            let num = this.form.num || 1;
            this.calcText = "单次价格" + price + "元";
            this.form.discount_price = (price * num).toFixed(2);
            this.form.price = (this.venue_price * num).toFixed(2);
          }
        );
      }
    },

    getPoint(price, settle) {
      let url = "/user/masterSetPrice/querySettlePoint";
      return this.$axios.get(url, {
        params: {
          card_type: "专享卡",
          amout: price,
          settlement_cash: settle,
          type: "mechanism_offline",
        },
      });
    },
    getAddress(e) {
      this.form.province = e.province;
      this.form.city = e.city;
      this.form.district = e.district;
      this.form.mechanism_id = e.id;
      this.form.mechanism_name = e.mechanism_name;
    },
    editService() {
      this.form = {...this.Data};
      this.temp = Object.assign({},this.form);
    },
    open() {
      this.addServiceDialog = true;
    },
    updateForm() {
      this.$refs.courseForm.validate((valid) => {
        if (valid) {
          this.updateServie();
        }
      });
    },
    submitCourseForm() {
      this.$refs.courseForm.validate((valid) => {
        if (valid) {
          this.addNewService();
        }
      });
    },
    addNewService() {
      let url = "/user/venue/server/insert";
      this.if_clear = false;
      this.form.is_recommend = this.form.is_recommend ? 1 : 0;
      this.form.status = 1;
      this.$axios.post(url, this.form).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "添加成功!" });
          this.addServiceDialog = false;
          this.if_clear = true;
          this.form = {};
          this.$emit("success");
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    updateServie() {
      let data = ObjectChangedValue(this.temp, this.form);
      if (this.mode == "check") {
        data.status = 1;
      }
      if (Object.keys(data).length == 0) {
        this.$message("请修改数据后提交");
        return;
      }
      data.id = this.form.id;
      this.if_clear = false;
      data.is_recommend = data.is_recommend ? 1 : 0;
      let url = "/user/venue/server/update";
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "success", message: "修改成功!" });
          this.addServiceDialog = false;
          this.if_clear = true;
          this.form = {};
          this.$emit("success");
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    getCateList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      let data = {
        status: 2,
        type: 2,
        source: "课程",
      };
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.categories_child_list = res.data.data;
        });
    },
    // 机构查询远程查询
    searchOrgName(query) {
      if (query && typeof query == "string") {
        let url = "/user/mastermechanism/queryByMessage";
        let data = {
          type: "teach_paypal",
          pageSize: 30,
          currentPage: 1,
          status: 2,
        };
        const numberReg = /^\d/;
        numberReg.test(query)
          ? (data.mechanism_telephone = query)
          : (data.mechanism_name = query);
        this.$axios
          .get(url, {
            params: data,
          })
          .then((res) => {
            this.mechanism_name_list = res.data.data.rows;
          })
          .catch((err) => {});
      }
    },
    getVenuesList(query) {
      let url = "/user/mechanism/venue/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 0,
            name: typeof query == "string" && query != "" ? query : null,
            mechanism_id: this.form.mechanism_id
              ? this.form.mechanism_id
              : null,
          },
        })
        .then((res) => {
          this.venues_list = res.data.data.rows;
        })
        .catch((err) => {});
    },
    getCover(url) {
      this.form.image = url;
    },
    getProductImg(url) {
      this.form.introduction_img = url;
    },
  },
};
</script>

<style scoped>
.calc {
  padding: 0 0 8px 100px;
}
</style>